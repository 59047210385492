import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { NotifService } from './shared/notif.service';
import { environment as env } from 'src/environments/environment';

@Injectable()
export class NotifInterceptor implements HttpInterceptor {

  constructor(private notifService:NotifService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // Action en cas de réussite de la requête
          if (event instanceof HttpResponse && event.body && event.body.notif) {
            // this.dataService.notifications.push(...event.body.notif);
            const notifications = event.body.notif;
            //console.log(notifications);
            if (notifications.length)
              this.notifService.updateNotifications(notifications);
            //this.notifService.addNotification(notifications);
          }
          if (event instanceof HttpResponse && event.body && event.body.version && (event.body.version > env.appVersion)) {
            this.notifService.displayNewVersion();
            window.scroll(0,0);
          }
        },
        (error: any) => {
          // Action en cas d'échec de la requête
        }
      ),
      finalize(() => next.handle(request))
    );
  }
}
