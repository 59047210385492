import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {StepsModule} from 'primeng/steps';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CardModule} from 'primeng/card';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TableModule} from 'primeng/table';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TabViewModule} from 'primeng/tabview';
import {ChipsModule} from 'primeng/chips';
import {KeyFilterModule} from 'primeng/keyfilter';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DropdownModule} from 'primeng/dropdown';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {TooltipModule} from 'primeng/tooltip';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CalendarModule} from 'primeng/calendar';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {FileUploadModule} from 'primeng/fileupload';
import {ListboxModule} from 'primeng/listbox';
import {ProgressBarModule} from 'primeng/progressbar';
import {RatingModule} from 'primeng/rating';
import {TreeTableModule} from 'primeng/treetable';
import {CarouselModule} from 'primeng/carousel';
import { FullCalendarModule } from '@fullcalendar/angular';
import {ColorPickerModule} from 'primeng/colorpicker';
import {PasswordModule} from 'primeng/password';
import {SliderModule} from 'primeng/slider';


const PrimeNgModules = [
    StepsModule,
    ButtonModule,
    RadioButtonModule,
    AutoCompleteModule,
    CardModule,
    CheckboxModule,
    DialogModule,
    InputTextModule,
    ToggleButtonModule,
    TableModule,
    ProgressSpinnerModule,
    TabViewModule,
    ChipsModule,
    KeyFilterModule,
    InputSwitchModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    InputTextareaModule,
    CalendarModule,
    SelectButtonModule,
    MultiSelectModule,
    FileUploadModule,
    ListboxModule,
    ProgressBarModule,
    RatingModule,
    TreeTableModule,
    CarouselModule,
    FullCalendarModule,
    ColorPickerModule,
    PasswordModule,
    SliderModule
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...PrimeNgModules
  ],
  exports: [
    ...PrimeNgModules
  ]
})
export class PrimengSharedModule { }
