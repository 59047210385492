import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifService {

  private notificationSubject = new Subject<any[]>();
  notification$ = this.notificationSubject.asObservable();
  private notifications: any[] = [];
  // = [{severity:'success', summary:'dgfdgdfsgdfs', detail:'Message Content'},
  //   {severity:'info', summary:'Info', detail:'Message Content'}];

  updateNotifications(notifications: any[]) {
    this.notificationSubject.next(notifications);
  }

  addNotification(notification: any) {
    this.notifications.push(...notification);
    this.notificationSubject.next(this.notifications);
  }

  newAppVersion: Subject<boolean> = new Subject<boolean>();

  displayNewVersion(){
    this.newAppVersion.next(true);
  }
}
