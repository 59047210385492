import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment as env} from 'src/environments/environment';
import {DataService} from "../shared/data.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private HOST = env.HOST;

  constructor(private router: Router, private service: DataService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //Si le token n'a pas besoin d'etre rafraichit, on exécute direct la partie qui ajoute le token dans le else
    //S'il a besoin alors dabord, on le rafraichit puis on exécute la requete avec le token
    //L operateur switchmap permet de s'assurer que la requete initiale sera executée après le rafraichissement
    let exp = localStorage.getItem('token_exp');
    if (exp && Math.floor(Date.now() / 1000) > parseInt(exp)) {
      localStorage.removeItem('token_exp');
      localStorage.removeItem('token'); //decommenter pour passage a jwt
      return this.service.refreshToken().pipe(tap((cred: any) => {
          if (!cred?.error) {
            cred = cred.data;
            localStorage.setItem("token", cred.token); //decommenter pour passage a jwt
            localStorage.setItem("token_exp", cred.expire);
            this.service.refreshAccount();//On met a jour la liste des comptes avec le new token et exp
          }
        }), switchMap(() => {
          return this.copyAndResendRequest(request, next); //on doit retourner 2x (pour switchmap et pour interceptor)
        })
      )
    } else
      return this.copyAndResendRequest(request, next); //Si pas besoin de rafraichir le token on return interceptor
  }


  //Cette fonction copie la requete et la renvoie avec les tokens
  //Le fait de mettre dans une fonction permet de l'appeler si le token a besoin d'etre
  // rafraichit et si il n'a pas besoin, sans ecrire tout en double
  copyAndResendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(this.HOST)) //(protege de l'envoi du token vers des inconnus)
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
          .set('Client', `Bearer ${localStorage.getItem('token_cl')}`)
          .set('Token', `Bearer ${localStorage.getItem('token_ad')}`)
      });

    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {
        if (err.status !== 401)
          return;
        else if (err instanceof HttpErrorResponse) {
          console.error(err);
          if (err.error?.admin && err.error.unauthorized) {
            this.router.navigate(['gestion/login'])
          }
          else if (err.error?.client && err.error?.unauthorized) {
            localStorage.removeItem('token_cl');
            localStorage.removeItem('utilisateur');
            this.router.navigate(['acces-client/login'])
          } else {
            //let url = this.router.url;
              this.service.deleteAccount();
              this.service.disconnect();
              this.router.navigate(['backoffice/login']);
          }
        }
      }));
  }
}
