<div *ngIf="!isOnline" class="container-fluid py-3">
      <span class="badge bg-danger" *ngIf="!isOnline">Hors connexion</span>
</div>
<div class="contenair-notif">
  <p-messages [(value)]="notifications"></p-messages>
</div>

<div style="height: 45px;color:#541554;font-weight: bold;border:none" *ngIf="modalVersion">
    <button type="button" pButton icon="pi pi-times" style="background-color: white;color: #541554;font-size: 3px" (click)="closeVersion()"></button>
    Une nouvelle version est disponible ! Veuillez mettre à jour l'application <a href="" (click)="updateVersion()">Mettre à jour</a>
</div>

<!--<div class="w-100 position-absolute bottom-0" *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS' || modalPwaPlatform === 'isBrowser' ">
  <div class="alert alert-secondary m-2">
    <button type="button" (click)="closePwa()">Fermer</button>
    &lt;!&ndash; Android &ndash;&gt;
    <div *ngIf="modalPwaPlatform === 'ANDROID' || 'isBrowser'" (click)="addToHomeScreen()">
      Ajouter l'application à l'écran d'accueil
    </div>
    &lt;!&ndash; iOS with Safari &ndash;&gt;
    <div *ngIf="modalPwaPlatform === 'IOS'">
      Pour ajouter l'application, utilisez le bouton menu
      <img src="assets/safari_action_button_38x50.png" class="ios-menu m-0" />
      et choisissez "Ajouter à l'écran d'accueil".
      <i class="bi bi-plus-square"></i>
    </div>
  </div>
</div>-->


<main>
	<router-outlet></router-outlet>
</main>
