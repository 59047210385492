import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { TokenInterceptor } from './backoffice/token.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {AdminInterceptor} from "./gestion/admin.interceptor";
import { PrimengSharedModule } from './shared/primeng-modules.module';
import { NotifInterceptor } from './notif.interceptor';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// import { StringToDatePipe } from './string-to-date.pipe';
registerLocaleData(localeFr, 'fr');


@NgModule({
  declarations: [
    AppComponent,
    // StringToDatePipe
  ],
  imports: [
    BrowserModule,
    PrimengSharedModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [{provide: LOCALE_ID, useValue: 'fr' },{
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}/*,{
  provide: HTTP_INTERCEPTORS,
  useClass:AdminInterceptor,
  multi: true
}*/,{
    provide: HTTP_INTERCEPTORS,
    useClass:NotifInterceptor,
    multi: true
  },
    InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


